import { SponsorLogoProps, SponsorType } from './index';

export const SPONSORS = new Map<
  SponsorType,
  Array<SponsorLogoProps>
>([
    [ 'Session',
      [
          { image: 'microsoft.png', alt: '日本マイクロソフト株式会社', url: 'https://news.microsoft.com/ja-jp/' },
          { image: 'ulsystems.png', alt: 'ウルシステムズ株式会社', url: 'https://www.ulsystems.co.jp/' },
          { image: 'line.png', alt: 'LINE株式会社', url: 'https://engineering.linecorp.com/ja/' },
          { image: 'visional.png', alt: 'Visional（株式会社ビズリーチ）', url: 'https://www.visional.inc/ja/index.html'},
          { image: 'casareal.png', alt: '株式会社カサレアル', url: 'https://www.casareal.co.jp/'},
          { image: 'techmatrix.png', alt: 'テクマトリックス株式会社', url: 'https://www.techmatrix.co.jp/product/jtest/index.html' },
          { image: 'simplex.png', alt: 'シンプレクス・ホールディングス株式会社', url: 'https://www.simplex.inc/' },
          { image: 'ibm.png', alt: '日本アイ・ビー・エム株式会社', url: 'https://www.ibm.com/jp-ja/cloud/websphere-liberty' },
          { image: 'gmo_internet_group.png', alt: 'GMOインターネットグループ株式会社', url: 'https://developers.gmo.jp/' },
          { image: 'cdata.png', alt: 'CData Software Japan 合同会社', url: 'https://www.cdata.com/jp/' },
          { image: 'redhat.png', alt: 'レッドハット株式会社', url: 'https://www.redhat.com/ja/global/japan' },
          { image: 'cybozu.png', alt: 'サイボウズ株式会社', url: 'https://tech.cybozu.io/' },
      ]
    ],
    [
      'Cm',
      []
    ],
    [
        'Logo',
        [
            { image: 'rakusu.png', alt: '株式会社ラクス', url: 'https://career-recruit.rakus.co.jp/career_engineer/' },
        ]
    ]
  ]
);
